<!-- 
  How To Use:
  Set the Props: 
    - hozAlign: set the justify spacing i.e. start,  end, center, between, around
                You can also set breakpoint specific spacing by setting the mobile alignment first 
                i.e. start md:justify-between, lg:justify-around
    - iconGap: set the icon gap using full tailwind class. i.e gap-2, gap-3, gap-4 (this can also add any other class) 
              This prop can be ignored if spacing changes are not required or if set alignment is set to around or between
    - onDark: set to true if icons are on dark background
    - data: { // create a object with the social icons you want to display
        facebook: {
          share: boolean // set to true if you want to share the current page
          social: boolean // set to true if you want to link to the AussieBB social page
          custom: string // set to the custom link you want to use
        }
        youtube: { // does not have a share feature
          social: boolean
          custom: string
        }
        instagram: { // does not have a share feature
          social: boolean
          custom: string
        }
        threads: {
          share: boolean
          social: boolean
          custom: string
        }
        twitter: {
          share: boolean
          social: boolean
          custom: string
        }
        linkedin: {
          share: boolean
          social: boolean
          custom: string
        }
        email: {
          share: boolean // set to true if you want to share the current page in an email
          custom: string
        }
        copy: {
          text: string // set to the text you want to copy to the clipboard
        }
      }
-->

<template>
  <div
    :class="[
      `flex items-center justify-${hozAlign}`,
      iconGap ? iconGap : 'gap-6 md:gap-8',
    ]"
  >
    <!-- Facebook -->
    <NuxtLink
      v-if="data.facebook"
      :to="
        data.facebook.share
          ? `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`
          : data.facebook.social
            ? 'https://www.facebook.com/aussiebroadband/?fref=nf'
            : data.facebook.custom
      "
      external
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconSocialFacebook
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Facebook</span>
    </NuxtLink>
    <!-- Youtube -->
    <NuxtLink
      v-if="data.youtube"
      :to="
        data.youtube.social
          ? `https://www.youtube.com/@aussie.broadband`
          : data.youtube.custom
      "
      external
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconSocialYoutube
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Youtube</span>
    </NuxtLink>
    <!-- Instagram -->
    <NuxtLink
      v-if="data.instagram"
      :to="
        data.instagram.social
          ? 'https://www.instagram.com/aussiebroadband/'
          : data.instagram.custom
      "
      external
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconSocialInstagram
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Instagram</span>
    </NuxtLink>
    <!-- Threads -->
    <NuxtLink
      v-if="data.threads"
      :to="
        data.threads.share
          ? `https://www.threads.net/intent/post?text=${shareUrl}`
          : data.threads.social
            ? 'https://www.threads.net/@aussiebroadband'
            : data.threads.custom
      "
      external
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconSocialThreads
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Threads</span>
    </NuxtLink>
    <!-- Twitter -->
    <NuxtLink
      v-if="data.twitter"
      :to="
        data.twitter.share
          ? `https://twitter.com/intent/tweet?text=${shareUrl}`
          : data.twitter.social
            ? 'https://twitter.com/aussie_bb'
            : data.twitter.custom
      "
      external
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconSocialTwitter
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Twitter</span>
    </NuxtLink>
    <!-- Linkedin -->
    <NuxtLink
      v-if="data.linkedin"
      :to="
        data.linkedin.share
          ? `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`
          : data.linkedin.social
            ? 'https://www.linkedin.com/company/aussie-broadband/'
            : data.linkedin.custom
      "
      external
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconSocialLinkedin
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Linkedin</span>
    </NuxtLink>
    <!-- Email -->
    <NuxtLink
      v-if="data.email"
      :to="`mailto:${data.email.share ? `?subject=Check out this post from Aussie Broadband&body=${shareUrl}` : data.email.custom}`"
      target="_blank"
    >
      <IconSocialEmail
        class="size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">Email</span>
    </NuxtLink>
    <!-- Copy -->
    <button v-if="data.copy" :aria-pressed="copied" @click="handleCopy">
      <IconCheck v-if="copied" class="size-8 fill-green-800 p-1.5" />
      <IconCopy
        v-else
        class="p.1.5 size-6 md:size-7 lg:size-8"
        :class="
          onDark
            ? 'fill-grey-100 hover:fill-grey-300'
            : 'fill-grey-600 hover:fill-grey-400'
        "
      />
      <span class="sr-only">{{
        copied ? "Copied to clipboard" : "Copy to clipboard"
      }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  iconGap?: string;
  hozAlign: string;
  onDark?: boolean;
  data: {
    facebook?: {
      share?: boolean;
      social?: boolean;
      custom?: string;
    };
    youtube?: {
      social?: boolean;
      custom?: string;
    };
    instagram?: {
      social?: boolean;
      custom?: string;
    };
    threads?: {
      share?: boolean;
      social?: boolean;
      custom?: string;
    };
    twitter?: {
      share?: boolean;
      social?: boolean;
      custom?: string;
    };
    linkedin?: {
      share?: boolean;
      social?: boolean;
      custom?: string;
    };
    email?: {
      share?: boolean;
      custom?: string;
    };
    copy?: {
      text: string;
    };
  };
}>();

const { path } = useRoute();
const shareUrl = `https://www.aussiebroadband.com.au${path}`;
const copied = ref(false);
const timeout = ref();

const handleCopy = () => {
  if (props.data.copy) {
    navigator.clipboard.writeText(props.data.copy.text);
    copied.value = true;

    if (timeout.value) clearTimeout(timeout.value);

    timeout.value = setTimeout(() => (copied.value = false), 3000);
  }
};
</script>
