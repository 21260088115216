<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.922 22C8.938 21.98 6.644 20.996 5.102 19.076C3.732 17.366 3.024 14.988 3 12.008V11.994C3.024 9.012 3.732 6.636 5.104 4.926C6.644 3.004 8.94 2.02 11.922 2H11.934C14.222 2.016 16.136 2.604 17.622 3.748C19.02 4.824 20.004 6.356 20.546 8.304L18.846 8.778C17.926 5.478 15.598 3.792 11.926 3.766C9.502 3.784 7.668 4.546 6.476 6.03C5.362 7.42 4.786 9.428 4.764 12C4.786 14.572 5.362 16.58 6.478 17.97C7.67 19.456 9.504 20.218 11.928 20.234C14.114 20.218 15.56 19.708 16.762 18.53C18.134 17.186 18.11 15.536 17.67 14.532C17.412 13.94 16.942 13.448 16.308 13.074C16.148 14.2 15.79 15.112 15.238 15.8C14.5 16.718 13.454 17.22 12.13 17.292C11.128 17.346 10.162 17.11 9.414 16.624C8.528 16.05 8.01 15.174 7.954 14.154C7.9 13.162 8.294 12.25 9.062 11.586C9.796 10.952 10.828 10.58 12.048 10.51C12.946 10.46 13.788 10.5 14.564 10.628C14.46 10.01 14.252 9.518 13.94 9.164C13.512 8.676 12.85 8.428 11.974 8.422C11.966 8.422 11.958 8.422 11.95 8.422C11.246 8.422 10.29 8.616 9.682 9.522L8.218 8.54C9.034 7.328 10.358 6.66 11.95 6.66C11.962 6.66 11.974 6.66 11.986 6.66C14.648 6.676 16.234 8.306 16.392 11.15C16.482 11.188 16.572 11.228 16.66 11.268C17.902 11.852 18.81 12.736 19.288 13.826C19.952 15.344 20.014 17.818 17.998 19.792C16.456 21.3 14.586 21.982 11.934 22H11.922ZM12.764 12.258C12.562 12.258 12.358 12.264 12.148 12.276C10.618 12.362 9.664 13.064 9.718 14.062C9.774 15.108 10.928 15.594 12.038 15.534C13.058 15.48 14.386 15.082 14.61 12.442C14.046 12.32 13.428 12.258 12.764 12.258Z"
    />
  </svg>
</template>
