<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00009 7.92485C3.99997 7.93263 3.99997 7.9404 4.00009 7.94816V16.0145C4.00009 17.2311 4.97691 18.2174 6.18188 18.2174H17.8181C19.0231 18.2174 19.9999 17.2311 19.9999 16.0145V7.94817C20 7.9404 20 7.93262 19.9999 7.92484V7.2029C19.9999 5.98627 19.0231 5 17.8181 5H6.18188C4.97691 5 4.00009 5.98627 4.00009 7.2029V7.92485ZM5.45462 7.2029C5.45462 6.79736 5.78023 6.4686 6.18188 6.4686H17.8181C18.2198 6.4686 18.5454 6.79736 18.5454 7.2029V7.5338L12.363 11.5004L12.3613 11.5015C12.2529 11.5696 12.1277 11.6058 12 11.6058C11.8723 11.6058 11.7471 11.5696 11.6387 11.5015L5.45462 7.5338V7.2029ZM18.5454 9.27382V16.0145C18.5454 16.42 18.2198 16.7488 17.8181 16.7488H6.18188C5.78023 16.7488 5.45462 16.42 5.45462 16.0145V9.27383L10.8609 12.7425L10.8648 12.745C11.2051 12.9602 11.5985 13.0744 12 13.0744C12.4015 13.0744 12.795 12.9602 13.1352 12.745L18.5454 9.27382Z"
    />
  </svg>
</template>
