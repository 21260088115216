<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7778 22H4.22222C2.99492 22 2 21.0051 2 19.7778V4.22222C2 2.99492 2.99492 2 4.22222 2H19.7778C21.0051 2 22 2.99492 22 4.22222V19.7778C22 21.0051 21.0051 22 19.7778 22ZM16.2543 19.2222H19.2222V13.1253C19.2222 10.5457 17.7599 9.29839 15.7174 9.29839C13.6739 9.29839 12.8139 10.8897 12.8139 10.8897V9.59259H9.9537V19.2222H12.8139V14.1673C12.8139 12.8128 13.4374 12.0068 14.6308 12.0068C15.7277 12.0068 16.2543 12.7813 16.2543 14.1673V19.2222ZM4.77778 6.55472C4.77778 7.53602 5.56724 8.33167 6.54149 8.33167C7.51573 8.33167 8.30472 7.53602 8.30472 6.55472C8.30472 5.57343 7.51573 4.77778 6.54149 4.77778C5.56724 4.77778 4.77778 5.57343 4.77778 6.55472ZM8.04706 19.2222H5.0646V9.59259H8.04706V19.2222Z"
    />
  </svg>
</template>
