<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 8.5C21.5 6.875 20.75 5.75 19 5.5C16.25 5 12 5 12 5C12 5 7.75 5 5 5.5C3.25 5.75 2.375 6.875 2.25 8.5C2 10.125 2 12.5 2 12.5C2 12.5 2 14.875 2.25 16.5C2.5 18.125 3.25 19.25 5 19.5C7.75 20 12 20 12 20C12 20 16.25 20 19 19.5C20.75 19.125 21.5 18.125 21.75 16.5C22 14.875 22 12.5 22 12.5C22 12.5 22 10.125 21.75 8.5ZM9.5 16.25V8.75L15.75 12.5L9.5 16.25Z"
    />
  </svg>
</template>
