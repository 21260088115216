<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6.79839C9.17742 6.79839 6.83871 9.09677 6.83871 11.9597C6.83871 14.8226 9.1371 17.121 12 17.121C14.8629 17.121 17.1613 14.7823 17.1613 11.9597C17.1613 9.1371 14.8226 6.79839 12 6.79839ZM12 15.2661C10.1855 15.2661 8.69355 13.7742 8.69355 11.9597C8.69355 10.1452 10.1855 8.65323 12 8.65323C13.8145 8.65323 15.3065 10.1452 15.3065 11.9597C15.3065 13.7742 13.8145 15.2661 12 15.2661Z"
    />
    <path
      d="M17.3629 7.84677C18.0087 7.84677 18.5323 7.32324 18.5323 6.67742C18.5323 6.0316 18.0087 5.50806 17.3629 5.50806C16.7171 5.50806 16.1935 6.0316 16.1935 6.67742C16.1935 7.32324 16.7171 7.84677 17.3629 7.84677Z"
    />
    <path
      d="M20.3871 3.65323C19.3387 2.56452 17.8468 2 16.1532 2H7.84677C4.33871 2 2 4.33871 2 7.84677V16.1129C2 17.8468 2.56452 19.3387 3.69355 20.4274C4.78226 21.4758 6.23387 22 7.8871 22H16.1129C17.8468 22 19.2984 21.4355 20.3468 20.4274C21.4355 19.379 22 17.8871 22 16.1532V7.84677C22 6.15323 21.4355 4.70161 20.3871 3.65323ZM20.2258 16.1532C20.2258 17.4032 19.7823 18.4113 19.0565 19.0968C18.3306 19.7823 17.3226 20.1452 16.1129 20.1452H7.8871C6.67742 20.1452 5.66935 19.7823 4.94355 19.0968C4.21774 18.371 3.85484 17.3629 3.85484 16.1129V7.84677C3.85484 6.6371 4.21774 5.62903 4.94355 4.90323C5.62903 4.21774 6.67742 3.85484 7.8871 3.85484H16.1935C17.4032 3.85484 18.4113 4.21774 19.1371 4.94355C19.8226 5.66935 20.2258 6.67742 20.2258 7.84677V16.1532Z"
    />
  </svg>
</template>
