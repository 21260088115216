<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9047 10.4696L21.3513 2H19.5873L13.1187 9.35254L7.956 2H2L9.80867 13.1194L2 22H3.764L10.5907 14.2338L16.044 22H22M4.40067 3.30158H7.11067L19.586 20.7624H16.8753"
    />
  </svg>
</template>
