<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12.0503C2 17.0251 5.6 21.1457 10.35 22L10.5336 21.849V14.795H8.11111V11.9931H10.5336V9.79158C10.5336 7.28986 12.084 5.88889 14.3127 5.88889C14.991 5.88889 15.7662 5.98896 16.4444 6.08903V8.64079H15.1848C14.022 8.64079 13.7313 9.2412 13.7313 10.0418V11.9931H16.2991L15.863 14.795H13.7313V21.8499C13.6588 21.8635 13.5863 21.8762 13.5137 21.888L13.65 22C18.4 21.1457 22 17.0251 22 12.0503C22 6.52261 17.5 2 12 2C6.5 2 2 6.52261 2 12.0503Z"
    />
  </svg>
</template>
